import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React from "react";

const Map = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 11.4347859,
    lng: 76.7392758,
  };

  return (
    <>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14155.650360333528!2d76.7404635!3d11.4346894!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8bde3f16b2c77%3A0xe205b34db794aaff!2sDreamCloudz%20Infra%20And%20Properties%20Private%20Limited!5e1!3m2!1sen!2sin!4v1732770966125!5m2!1sen!2sin"></iframe>
    </>
  );
};

export default Map;
