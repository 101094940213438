import React from "react";
import Paragraph from "./Paragraph";
import SubHeading from "./SubHeading";

const FormText = () => {
  return (
    <div className="col-lg-5">
      <div className="section-header">
        <SubHeading title="Get in Touch"></SubHeading>
        <Paragraph
          text="Whether you have a grand vision in mind or a restoration project that needs expert care, DreamCloudz Infra And Properties Pvt Ltd is your trusted partner. Contact us today to embark on a construction journey that combines the timeless allure of Roman classical architecture with modern innovation.

Thank you for considering DreamCloudz Infra And Properties Pvt Ltd for your construction needs. We look forward to turning your dreams into reality."
        ></Paragraph>
      </div>
    </div>
  );
};

export default FormText;
