import React from "react";
import { EffectFade, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsInner from "../../components/ProjectElements/ProjectDetailsInner";
import ProjectDetailsSlider from "../../components/ProjectElements/ProjectDetailsSlider";
import SideBar02 from "../../components/SideBar02";
import ShopSidebar from "../../components/forJSON/ShopSidebar";
import WidgetTitle from "../../components/WidgetTitle";

const ProjectDetailsContainer = () => {
  return (
    <section className="project-details bg-dark-200">
      <div className="container">
        <div className="gallery_slider">
          <div className="swiper swiper_gallery">
            <Swiper
              modules={[EffectFade, Navigation]}
              effect="slide"
              slidesPerView={1}
              loop={true}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
            >
              <SwiperSlide>
                <ProjectDetailsSlider img="images/portfolio/details/sl-1.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <ProjectDetailsSlider img="images/portfolio/details/sl-2.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <ProjectDetailsSlider img="images/portfolio/details/sl-3.jpg" />
              </SwiperSlide>
            </Swiper>

            {/* <!-- Add Buttons --> */}
            <div className="swiper-navigation">
              <div className="swiper-button-prev">
                <i className="bi bi-arrow-left"></i>
              </div>
              <div className="swiper-button-next">
                <i className="bi bi-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4">
            <SideBar02 />

            {/* Specification */}
            <div className="shop_sidebar">
              <div id="category" className="widget widget_categories">
                <WidgetTitle title="Specifications" className="widget-title" />
                <ul className="sidebar_category">
                  <li className="category_item">R.C.C Framed Structure</li>
                  <li className="category_item">
                    Autoclaved Aerated Concrete blocks for Constructions
                  </li>
                  <li className="category_item">
                    Interior & Exterior with high quality emulsion paints for
                    long life
                  </li>
                  <li className="category_item">
                    2 x 2 tiles for entire flat flooring
                  </li>
                  <li className="category_item">
                    Anti-skid tile for the toilet floor
                  </li>
                  <li className="category_item">
                    For toilet wall, Glazed tiles with printed designs up to 7'
                  </li>
                  <li className="category_item">
                    UPVC windows and glass shutters with fixed grill
                  </li>
                  <li className="category_item">
                    Cera (or) equivalent sanitary fittings
                  </li>
                  <li className="category_item">
                    Cera (or) equivalent quality CP fittings
                  </li>
                  <li className="category_item">
                    Three phase EB connection with Circuit Changer
                  </li>
                  <li className="category_item">
                    TV and telephone points in living room
                  </li>
                </ul>
              </div>
            </div>

            {/* amenities */}
            <div className="shop_sidebar">
              <div id="brand" className="widget widget_brand">
                <h2 className="widget-title">
                  Facilities <span className="title-line"></span>
                </h2>
                <div className="sidebar_brand">
                  <form method="post">
                    <div className="form-check">
                      <label>Flower Garden </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox1"
                        name="checkbox1"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Solar Lighting </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox2"
                        name="checkbox2"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Wi-Fi Connectivity</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox3"
                        name="checkbox3"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>School</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox4"
                        name="checkbox4"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>College</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox5"
                        name="checkbox5"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Power Back up Lift</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>24x7 Security</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>24/7 Water Supply</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Children's Play Area</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Car Parking</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Landscape Garden</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Lift(s)</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Open Space</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Security Cabin</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Senior Citizen Sitout</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>DTH Television</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Internal Street Lights</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                    <div className="form-check">
                      <label>Fire Fighting Systems</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkbox6"
                        name="checkbox6"
                        defaultChecked
                        value=""
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-8">
            <ProjectDetailsInner />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetailsContainer;
