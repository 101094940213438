import React from "react";

const TestimonialSlide = ({ name, des }) => {
  return (
    <div className="testimonial-block text-center">
      <p>{des}</p>
      <h6 className="text-olive">{name}</h6>
    </div>
  );
};

export default TestimonialSlide;
