import React from "react";
import SubHeading from "./SubHeading";

const ClientsLogo = () => {
  return (
    <>
      <div className=" mb-displaynn">
        <SubHeading title="Architech"></SubHeading>
      </div>
      <div className="partner_flex mb-displaynn">
        <div className="partner_content">
          <img src="images/partner/1.png" alt="img" />
        </div>
      </div>
      <div className=" mb-displaynn">
        <SubHeading title="Our Brands"></SubHeading>
      </div>
      <div className="partner_flex mb-displaynn">
        <div className="partner_content">
          <img src="images/partner/2.png" alt="img" />
        </div>
        <div className="partner_content">
          <img src="images/partner/3.png" alt="img" />
        </div>
        <div className="partner_content">
          <img src="images/partner/4.png" alt="img" />
        </div>
        <div className="partner_content">
          <img src="images/partner/5.png" alt="img" />
        </div>
        <div className="partner_content">
          <img src="images/partner/6.png" alt="img" />
        </div>
        <div className="partner_content">
          <img src="images/partner/7.png" alt="img" />
        </div>
      </div>
    </>
  );
};

export default ClientsLogo;
