import React from "react";
import Faq from "react-faq-component";

const data = {
  title: "",
  rows: [
    {
      title: "Residential Construction",
      content:
        "We specialize in crafting luxurious and functional homes that reflect the unique personality and lifestyle of our clients.",
    },
    {
      title: "Commercial Construction",
      content:
        "From offices to retail spaces, we design and construct spaces that foster productivity and leave a lasting impression.",
    },
    {
      title: "Renovations and Restorations",
      content:
        "We breathe new life into old structures, preserving their historical significance while enhancing their functionality and aesthetic appeal.",
    },
    {
      title: "Architectural Design",
      content:
        "Our team of talented architects and designers collaborates closely with clients to create custom plans that bring their visions to life.",
    },
    {
      title: "Project Management",
      content:
        "We oversee every aspect of the construction process, ensuring projects are completed on time and within budget. ",
    },
  ],
};
const MissionContainer = () => {
  return (
    <section className="mission">
      <div className="container">
        <div className="mission_top_part">
          {/* <div className="section-header text-center">
            <h6 className="text-white text-uppercase">Our MISSION</h6>
            <p className="text-gray-600">
              Our organizational sprit believes that where design meets passion
              & love with space, good architecture happens here
            </p>
          </div> */}
          <div className="has_line"></div>
          <img
            src="images/bg/about_bg.jpg"
            style={{ filter: "opacity(0.4)" }}
            alt=""
          />
        </div>

        <div className="mission_bottom_part section-header">
          <div className="row justify-content-center">
            <div className="col-lg-4 pe-lg-4">
              <div className="section-header">
                <h3 className="text-white text-uppercase border-line">
                  ABOUT Dream Cloudz Infra And Properties
                </h3>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <div className="mission_content">
                <p>
                  Welcome to DreamCloudz Infra And Properties Pvt Ltd, where
                  craftsmanship meets innovation in the realm of construction.
                  Established in the picturesque town of Ooty, India, in the
                  year 2007, our journey began with a vision to blend the
                  timeless elegance of Roman classical architecture with modern
                  construction techniques.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mission_top_part">
          <div className="section-header text-center">
            <div className="has_line"></div>
            <h6 className="text-white text-uppercase">Our Story</h6>
            <p className="text-gray-600">
              DreamCloudz Infra And Properties Pvt Ltd was founded with a
              passion for creating architectural marvels that stand the test of
              time. Our commitment to excellence and dedication to every project
              has earned us a reputation as one of the leading construction
              companies in the region.
            </p>
          </div>
          <div className="section-header text-center">
            <div className="has_line"></div>
            <h6 className="text-white text-uppercase">Our Philosophy</h6>
            <p className="text-gray-600">
              At DreamCloudz, we believe that construction is an art form, and
              each project is a canvas waiting to be transformed into a
              masterpiece. Drawing inspiration from the grandeur of Roman
              classical architecture, we strive to infuse a sense of
              timelessness, sophistication, and durability into every structure
              we build.
            </p>
          </div>
          <div
            className="section-header text-center"
            style={{ marginBottom: "0px" }}
          >
            <div className="has_line"></div>
            <h6 className="text-white text-uppercase">Our Commitment</h6>
            <p className="text-gray-600">
              At DreamCloudz, our commitment to quality, sustainability, and
              client satisfaction is unwavering. We use the latest construction
              technologies and eco-friendly practices to minimize our
              environmental footprint while delivering exceptional results. Our
              team of skilled craftsmen, engineers, and project managers work
              tirelessly to exceed our clients' expectations and turn dreams
              into reality.
            </p>
          </div>
        </div>
        {/* <div className="mission_bottom_part section-header">
          <div className="row justify-content-center">
            <div className="col-lg-4 pe-lg-4">
              <div className="section-header">
                <h3 className="text-white text-uppercase border-line">
                  Our Expertise
                </h3>
                <p
                  className="text-gray-600 "
                  style={{ marginTop: "42px", textAlign: "end" }}
                >
                  Over the years, we have honed our skills and expertise in a
                  wide range of construction services, including:
                </p>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4">
              <Faq
                data={data}
                styles={{
                  bgColor: "white",
                  titleTextColor: "#48482a",
                  rowTitleColor: "#78789a",
                  rowTitleTextSize: "large",
                  rowContentColor: "#48484a",
                  rowContentTextSize: "16px",
                  rowContentPaddingTop: "10px",
                  rowContentPaddingBottom: "10px",
                  arrowColor: "black",
                }}
              />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default MissionContainer;
