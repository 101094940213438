import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionGridLines from "../components/SectionGridLines";
import TestimonialSlide from "../components/TestimonialSlide";

const Testimonials = () => {
  return (
    <section className="testimonial box_padding pb-0 pt-mb-0">
      <SectionGridLines />
      <div className="has_line_lg"></div>

      <div className="testimonial_inner bg-black">
        <div className="swiper swiper_testimonial">
          <Swiper
            modules={[Pagination]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
              speed: 5000,
            }}
            slidesPerView={1.5}
            loop={true}
            spaceBetween={150}
            centeredSlides={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
          >
            <SwiperSlide>
              <TestimonialSlide
                name="Vinoth Kumar"
                des="Working with DreamCloudz was an absolute dream come true! From the
        moment we contacted them for our home renovation project, their team was
        attentive, professional, and creative. They transformed our space into
        something beyond our wildest imagination. We couldn't be happier with
        the results!"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialSlide
                name="Kiruthika"
                des="DreamCloudz exceeded all our expectations! As first-time home builders, we were nervous about the process, but DreamCloudz made it seamless and enjoyable. Their attention to detail and commitment to quality are unmatched. Our new home is everything we dreamed of and more. Thank you, DreamCloudz!"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialSlide
                name="Sai Sudharsan"
                des="I can't recommend DreamCloudz enough! Their expertise in construction, land development, and interior design is evident in every aspect of their work. They listened to our needs and preferences and delivered a space that perfectly reflects our style. If you're looking for a top-notch company for your project, look no further than DreamCloudz!"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialSlide
                name="Sri Devi"
                des="DreamCloudz turned our house into a home! Their team's professionalism and dedication to excellence are truly commendable. They were with us every step of the way, from the initial planning stages to the final touches. The result is a space that we absolutely love and are proud to call our own. Thank you, DreamCloudz, for making our dreams a reality!"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialSlide
                name="Selvaraj"
                des="Choosing DreamCloudz for our construction and interior design needs was one of the best decisions we made. Their creativity, attention to detail, and commitment to customer satisfaction set them apart. Our project was completed on time and within budget, and the results are stunning. We couldn't be happier with our experience with DreamCloudz!"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
