import React from "react";
import PageHeader02 from "../../components/PageHeader02";
import SectionGridLines from "../../components/SectionGridLines";
import SectionHeader from "../../components/SectionHeader";

function Career() {
  return (
    <>
      <PageHeader02 bg="bg-1.jpg" title="" />

      <section className="projects packery">
        <SectionGridLines></SectionGridLines>
        <div className="large_font">
          <h2 className="floating_element text-dark-200 d-flex justify-content-center">
            Career
          </h2>
        </div>
        <div className="container">
          <div className="section-header text-center has_line">
            <h2 className="text-white">CAREER</h2>
            <div className="section-desc row align-items-center justify-content-center">
              <div className="col-lg-12 text-end">
                <p className="textCenter">
                  There is nothing like a concrete life plan to weigh you down.
                  Because if you always have one eye on some future goal, you
                  stop paying attention to the job at hand, miss opportunities
                  that might arise, and stay fixedly on one path, even when a
                  better, newer course might have opened up.
                </p>
              </div>
              <div className="col-lg-12 text-end">
                <div className="home_contact">
                  <form action="contact.php" method="POST">
                    <input
                      className="form-control form-control-lg"
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Your Name*"
                      required
                      aria-label=".form-control-lg example"
                    />
                    <input
                      className="form-control form-control-lg"
                      name="phone"
                      id="phone"
                      type="number"
                      placeholder="Your Phone No"
                      aria-label=".form-control-lg example"
                    />
                    <input
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Your Email*"
                      required
                      aria-label=".form-control-lg example"
                    />
                    <input
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      type="text"
                      placeholder="Your Education (Degree)*"
                      required
                      aria-label=".form-control-lg example"
                    />
                    <input
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      type="text"
                      placeholder="Your Experience*"
                      required
                      aria-label=".form-control-lg example"
                    />
                    <input
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      type="text"
                      placeholder="Your Skills*"
                      required
                      aria-label=".form-control-lg example"
                    />
                    <textarea
                      className="form-control pt-4"
                      name="message"
                      id="message"
                      placeholder="Your Address"
                      rows="3"
                    ></textarea>
                    <div className="btn_group">
                      <button type="submit" className="btn olive">
                        Send Mail
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
        </div>
      </section>
    </>
  );
}

export default Career;
