import $ from "jquery";
import React, { useEffect } from "react";
import SectionGridLines from "../../../components/SectionGridLines";

const Footer = () => {
  //

  useEffect(() => {
    // Totop Button
    $(".totop a").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    // Hide header on scroll down
    const nav = document.querySelector(".header");
    const scrollUp = "top-up";
    let lastScroll = 800;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 800) {
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
        return;
      }

      if (currentScroll > lastScroll) {
        // down
        nav.classList.add(scrollUp);
        $(".totop").addClass("show");
      } else if (currentScroll < lastScroll) {
        // up
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
      }
      lastScroll = currentScroll;
    });
  }, []);
  return (
    <>
      <footer className="footer bg-dark-200 box_padding">
        <div className="footer_inner bg-black">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-4 col-md-2 col-sm-2">
                <div className="section-header">
                  <h2>Contact</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="communication">
                  <div className="info_body">
                    <h6>Website</h6>
                    <h5>www.dreamcloudzinfra.com</h5>
                  </div>
                  <div className="info_body">
                    <h6>Email Address</h6>
                    <h5>dreamcloudzofficial@gmail.com</h5>
                  </div>
                  <div className="info_body">
                    <h6>Phone No</h6>
                    <h5>+91 9843227573</h5>
                  </div>
                  <div className="info_body">
                    <h6>Office Address</h6>
                    <h5>
                      Krismos Ville, 1/615, Doddabetta, Kenthorai, Ooty,
                      <br />
                      TamilNadu 643002
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="footer_elements d-flex align-items-center justify-content-end">
                  <div className="footer_elements_inner">
                    <div className="footer_logo">
                      <a href="/" className="light_logo">
                        <img src="images/logo.png" width="130px" alt="logo" />
                      </a>
                    </div>
                    <div className="footer_social">
                      <ul className="social_list">
                        <li className="facebook">
                          <a
                            href="https://www.facebook.com/dreamcloudzofficial/"
                            target="_blank"
                          >
                            <i className="bi bi-facebook"></i>
                          </a>
                        </li>

                        <li className="instagram">
                          <a
                            href="https://www.instagram.com/dreamcloudz_official/"
                            target="_blank"
                          >
                            <i className="bi bi-instagram"></i>
                          </a>
                        </li>
                        <li className="youbetube">
                          <a
                            href="https://www.youtube.com/c/DREAMCLOUDZINFRAANDPROPERTIESPRIVATELIMITED"
                            target="_blank"
                          >
                            <i className="bi bi-youtube"></i>
                          </a>
                        </li>
                        {/* <li className="twitter">
                          <a>
                            <i className="bi bi-twitter"></i>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    <div className="terms_condition">
                      <ul>
                        <li>
                          <a>Terms</a>
                        </li>
                        <li>
                          <a>Condition</a>
                        </li>
                        <li>
                          <a>Policy</a>
                        </li>
                      </ul>
                    </div>
                    <div className="copyright">
                      <p>
                        Dreamcloudzinfra {new Date().getFullYear()}. All Rights
                        Reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionGridLines />
        </div>
      </footer>

      <div className="totop">
        <a href="#">UP</a>
      </div>
    </>
  );
};

export default Footer;
