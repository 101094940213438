import React from "react";

const AsideInfo = () => {
  return (
    <div className="aside_info_wrapper">
      <button className="aside_close">
        <i className="bi bi-x-lg"></i>
      </button>
      <div className="aside_logo">
        <a href="/" className="light_logo">
          <img src="images/logo.png" alt="logo" />
        </a>
        <a href="/" className="dark_logo">
          <img src="images/logo.png" alt="logo" />
        </a>
      </div>
      <div className="aside_info_inner">
        <p>
          DreamCloudz Infra And Properties is a full-service design firm
          providing architecture architecture.
        </p>

        <div className="aside_info_inner_box">
          <h5>Contact Info</h5>
          <p>+91 9843227573</p>
          <p>
            Krismos Ville, 1/615, Doddabetta, Kenthorai, Ooty, TamilNadu 643002
          </p>
          <p>dreamcloudzofficial@gmail.com</p>
        </div>
        <div className="social_sites">
          <ul className="d-flex align-items-center justify-content-center">
            <li className="facebook">
              <a
                href="https://www.facebook.com/dreamcloudzofficial/"
                target="_blank"
              >
                <i className="bi bi-facebook"></i>
              </a>
            </li>

            <li className="instagram">
              <a
                href="https://www.instagram.com/dreamcloudz_official/"
                target="_blank"
              >
                <i className="bi bi-instagram"></i>
              </a>
            </li>
            <li className="youbetube">
              <a
                href="https://www.youtube.com/c/DREAMCLOUDZINFRAANDPROPERTIESPRIVATELIMITED"
                target="_blank"
              >
                <i className="bi bi-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AsideInfo;
