import React from "react";
import Form from "./Form";
import Paragraph from "./Paragraph";
import SectionGridLines from "./SectionGridLines";

const ContactForm = () => {
  return (
    <section className="contact_us bg-dark-200" id="enquiry-form">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="section-header">
              <h1 className="text-white text-uppercase mb-4">
                LET’S DISCUSS NEXT PROJECTS
              </h1>
              <Paragraph text="At DreamCloudz Infra And Properties Pvt Ltd, we bring your vision to life with unparalleled craftsmanship and innovative solutions. Whether it’s a residential masterpiece or a commercial marvel, our expertise in Roman classical and modern architecture ensures every project is a blend of elegance and functionality. Let’s collaborate to create something extraordinary!" />
            </div>
          </div>

          <div className="col-lg-6">
            <Form />
          </div>
        </div>
      </div>

      <SectionGridLines />
    </section>
  );
};

export default ContactForm;
