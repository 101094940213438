import React from "react";
import PageBanner03 from "../../containers/PageBanner03";
import ProjectGallery03 from "../../containers/ProjectContainers/ProjectGallery03";
import PageBanner from "../../containers/PageBanner";

const Project03 = () => {
  return (
    <>
      <PageBanner
        bg="header_bg.jpg"
        pageTitle=""
        title="For Sale"
        activePage="For Sale"
      ></PageBanner>
      <ProjectGallery03 text="Load More" className="btn gray" />
    </>
  );
};

export default Project03;
