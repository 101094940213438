import React from "react";
import IconBoxFlex from "../../components/IconBoxFlex";
import Paragraph from "../../components/Paragraph";
import SubHeading from "../../components/SubHeading";

const AboutContainer = () => {
  return (
    <section className="about bg-dark-100">
      <div className="large_font">
        <h2 className="floating_element text-dark-200 d-flex justify-content-center">
          About
        </h2>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-5">
            <div className="about_image">
              <img src="images/about/a1.jpg" alt="img" />
              <img src="images/about/e1.svg" alt="About" />
            </div>
          </div>
          <div className="col-lg-6 col-md-7">
            <div className="about_text_inner">
              <div className="about_text">
                <SubHeading title="ABOUT DreamCloudz Infra And Properties "></SubHeading>
                <Paragraph text="Welcome to DreamCloudz Infra And Properties Pvt Ltd, where craftsmanship meets innovation in the realm of construction. Established in the picturesque town of Ooty, India, in the year 2007, our journey began with a vision to blend the timeless elegance of Roman classical architecture with modern construction techniques."></Paragraph>
              </div>

              <div className="about_icon_box">
                <div className="row">
                  <div className="col-lg-6">
                    <IconBoxFlex
                      img="images/about/i2.svg"
                      text="We Are Creative"
                    ></IconBoxFlex>
                  </div>
                  <div className="col-lg-6">
                    <IconBoxFlex
                      img="images/about/i3.svg"
                      text="Honest and Dependable"
                    ></IconBoxFlex>
                  </div>
                  <div className="col-lg-6">
                    <IconBoxFlex
                      img="images/about/i1.svg"
                      text="We Are Always Improving"
                    ></IconBoxFlex>
                  </div>
                  <div className="col-lg-6">
                    <IconBoxFlex
                      img="images/about/i4.svg"
                      text="Quality Commitment"
                    ></IconBoxFlex>
                  </div>
                </div>
              </div>

              <div className="btn_group">
                <a href="/about" className="btn gray">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContainer;
