import React from "react";
import PageBanner from "../../containers/PageBanner";
import ProjectDetailsContainer02 from "../../containers/ProjectContainers/ProjectDetailsContainer02";

const ProjectDetails02 = () => {
  return (
    <main className="wrapper">
      <PageBanner
        pageTitle="For sale"
        title="Project Details"
        activePage="Candeur Crescent Apartments in Coimbatore "
      />
      <ProjectDetailsContainer02 />
    </main>
  );
};

export default ProjectDetails02;
