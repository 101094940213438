import React from "react";
// import RelatedProjects from "./RelatedProjects";
// import FormText from "../FormText";
// import Form from "../Form";
// import SubHeading from "../SubHeading";
// import Paragraph from "../Paragraph";

const ProjectDetailsInner = () => {
  return (
    <div className="project-details_inner">
      <div className="post_content">
        <div className="post-header">
          <h3 className="post-title">Residential 3 BHK House</h3>
        </div>
        <div className="fulltext">
          <p>
            DreamCloudz Infra And Properties is a full-service design firm
            providing architecture, master planning, urban design, interior
            architecture, space planning and programming. Our portfolio of
            completed work includes highly acclaimed and award-winning projects
            for clients around the country.
          </p>

          <h6 className="widget-title">
            Project Features
            <span className="title-line"></span>
          </h6>
          <p>
            We design with people in mind and use every expertise at our
            disposal.Our practice connects communities and is committed to the
            stewardship of place, people, and the environment.
          </p>
          <ul className="point_order">
            <li>
              The talent at Mrittik runs wide and deep. Across many markets,
              geographies & typologies.
            </li>
            <li>
              Our team members are some of the finest professionals in the
              industry.
            </li>
            <li>
              Organized to deliver the most specialized service possible and
              enriched by the depth.
            </li>
          </ul>
          <img
            src="images/portfolio/details/plan.jpg"
            width="100%"
            alt="plan"
          />
          <br />
          <br />

          <p>
            DreamCloudz Infra And Properties is a full-service design firm
            providing architecture, master planning, urban design, interior
            architecture, space planning and programming. Our portfolio of
            completed work includes highly acclaimed and award-winning projects
            for clients around the country.
          </p>

          <p>
            You don’t create unforgettable spaces all over the world with a
            single design tool. Our expertise in drawing people together is as
            broad as it is deep. It draws from a variety of disciplines, each
            one contributing to the bigger picture and sustainable growth. More
            than 3,000 projects fill our portfolio, but it’s the millions of
            people who experience them who matter most. We’ve grouped our work
            into five categories: places, venues, spaces, experiences and
            events. Click on the images below to learn more below about the
            impact our projects have on communities.
          </p>
        </div>

        <div className="comment-respond">
          <h3 className="comment-reply-title">
            Get in Touch. <span className="title-line"></span>
          </h3>
          <form className="comment-form" action="register.php" method="post">
            <p className="logged-in-as">
              Contact us today, and get reply with in 24 hours!
            </p>
            <div className="form-container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name*"
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="E-mail*"
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <input
                      type="number"
                      name="name"
                      className="form-control"
                      placeholder="Phone*"
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Text Here"
                      required=""
                    />
                  </div>
                </div>

                <div className="col-md-12 col-lg-12">
                  <div className="btn_group">
                    <button type="submit" className="btn olive">
                      Enquire Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsInner;
