import React from "react";
import Portfolio from "../../components/Portfolio";
// import PageBanner03 from "../../containers/PageBanner03";
import PageBanner from "../../containers/PageBanner";

const Project01 = () => {
  return (
    <>
      <PageBanner
        bg="header_bg.jpg"
        pageTitle=""
        title="Projects"
        activePage="Projects"
      ></PageBanner>
      <Portfolio text="Load More" className="btn gray" />
    </>
  );
};

export default Project01;
