import React from "react";
import ServiceCard from "../components/ServiceCard";

const Services = () => {
  return (
    <section className="services bg-dark-100  pb-mb-0 pb-0 pt-mobile">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <ServiceCard
              number="01"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="CONSTRUCTION"
              text="Our team, with all their experience in construction, helped us in both pre & post- building work. We have expertise in constructing individual homes, flats, villas, commercial and industrial buildings."
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="02"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="Land Developer"
              text="We are involved in providing our customer’s highly qualitative range of landscaping services. An offered array of landscaping services is rendered using the best quality input. "
            />
          </div>
          <div className="col-lg-4">
            <ServiceCard
              number="03"
              imgDark="images/icon_box/d1.svg"
              imgLight="images/icon_box/d1-light.svg"
              title="INTERIOR DESIGN"
              text="Our company has the best interior design partnership to provide wonderful interior work, like false ceiling, modular kitchen, cupboard, wallpaper, etc. "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
