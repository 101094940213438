import React from "react";
import PageBanner from "../../containers/PageBanner";
import Form from "../../components/Form";
import FormText from "../../components/FormText";
import SectionGridLines from "../../components/SectionGridLines";

import Faq from "react-faq-component";

const data = {
  title: "",
  rows: [
    {
      title: "Why Choose Real Estate Investment?",
      content: `Real estate investment has consistently proven to be one of the most stable and lucrative investment options available. Unlike volatile stock markets or unpredictable commodities, real estate offers a tangible and appreciating asset.
      <br> Here's why it should be your investment of choice: <br>

        <b><u>Steady Appreciation:</u></b> <br> Real estate values tend to appreciate over time, ensuring a reliable return on investment.
        
        Cash Flow: Rental income from real estate properties provides a consistent cash flow, offering financial security.<br>
        
       <b> <u>Diversification: </u></b> <br> Real estate investment diversifies your portfolio, reducing risk compared to other investments.<br>
        
        <b><u>Tangible Asset: </u></b> <br> Unlike stocks and bonds, real estate investments provide a tangible asset you can see and touch.
         `,
    },
    {
      title: "Why Invest in Our Real Estate Project?",
      content: `At our real estate project, we are dedicated to delivering exceptional value to our investors.<br> Here's why our project stands out:

        <br>Prime Location: Our project is strategically located in a thriving and high-demand area, ensuring strong rental and resale potential.<br>
        
        <b><u> Experienced Team: </u></b><br>Our team comprises seasoned real estate professionals who have a track record of successful projects.<br>
        
        <b><u> Modern Amenities: </u></b><br>We prioritize amenities and features that cater to today's discerning tenants and homebuyers.<br>
        
        <b><u>Sustainable Design: </u></b><br>We embrace eco-friendly and sustainable construction practices, contributing to a greener future.<br>
        
        <b><u>Transparent Communication: </u></b>We believe in transparent communication with our investors, providing regular updates on project progress and financial performance.<br>
        
        <b><u> Investment Options</u></b> <br>
        
        We understand that every investor has unique goals and financial circumstances. That's why we offer a variety of investment options tailored to your needs:<br>
        
        <b><u>Buy and Hold:</u></b><br> Purchase a property for rental income and long-term appreciation.<br>
        
        <b><u>Flipping:</u></b><br> Invest in properties with renovation potential, aiming for a quick turnaround and profit.<br>
        
        <b><u>Partnerships:</u></b><br> Explore opportunities for joint ventures or partnerships in our development projects.<br>
        
        <b><u>Real Estate Funds: </u></b><br>Diversify your investment through our real estate investment funds managed by experts.`,
    },
    {
      title: "Why Choose Us as Your Investment Partner?",
      content: `When you choose to invest in our real estate project, you're not just acquiring a property; you're joining a community of investors who share your vision of financial success. <br>Here's what sets us apart:<br>

        <b><u>Proven Track Record:</u></b><br> Our past successes and satisfied investors speak to our commitment to excellence.<br>
        
        <b><u>In-Depth Market Knowledge:</u></b><br> We have an in-depth understanding of local real estate markets, giving us an edge in identifying the best opportunities.<br>
        
        <b><u>Customized Investment Strategies:</u></b><br> We work closely with you to create a personalized investment strategy aligned with your financial goals.<br>
        
        <b><u>Commitment to Your Success:</u></b><br> Your success is our success, and we are dedicated to helping you achieve your investment objectives.<br>
        
        <b><u>Get Started Today</u></b><br>
        
        Don't miss out on this exciting investment opportunity in the world of real estate. Take the first step toward securing your financial future by exploring our project and investment options.<br>
        
        Contact us today to schedule a consultation with our team of experts. Together, we'll chart a course to financial growth, stability, and prosperity through real estate investment. Welcome to the future of your investment portfolio. Welcome to our real estate project.`,
    },
  ],
};
const config = {
  animate: true,
  arrowIcon: "V",
  openOnload: true,
  expandIcon: "+",
  collapseIcon: "-",
  tabFocus: true,
};
function Investment() {
  return (
    <>
      <PageBanner
        bg="header_bg.jpg"
        pageTitle=""
        title="Returns matter a lot. It's our capital."
        activePage="Investment"
      ></PageBanner>
      <section className="contact_us bg-dark-200">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="">
                <h3 className="text-white text-uppercase mb-4">
                  Welcome to Your Next Investment Opportunity in Real Estate
                </h3>
                <p>
                  <b>
                    Are you ready to embark on a journey towards financial
                    growth and prosperity?
                  </b>
                  <br /> Welcome to an exclusive investment opportunity that
                  promises to redefine your portfolio and secure your future. We
                  are thrilled to introduce you to our visionary real estate
                  project, where the future of investment meets the world of
                  real estate.
                </p>
              </div>
              <Faq
                data={data}
                styles={{
                  bgColor: "white",
                  titleTextColor: "#48482a",
                  rowTitleColor: "#78789a",
                  rowTitleTextSize: "large",
                  rowContentColor: "#48484a",
                  rowContentTextSize: "16px",
                  rowContentPaddingTop: "10px",
                  rowContentPaddingBottom: "10px",
                  arrowColor: "#cacaca",
                }}
                config={config}
              />
            </div>

            <div className="col-lg-6">
              <div className="home_contact">
                <form action="contact.php" method="POST">
                  <input
                    className="form-control form-control-lg"
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Your Name*"
                    required
                    aria-label=".form-control-lg example"
                  />
                  <input
                    className="form-control form-control-lg"
                    name="phone"
                    id="phone"
                    type="number"
                    placeholder="Your Phone No"
                    aria-label=".form-control-lg example"
                  />
                  <input
                    className="form-control form-control-lg"
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Your Email*"
                    required
                    aria-label=".form-control-lg example"
                  />
                  <textarea
                    className="form-control pt-4"
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    rows="3"
                  ></textarea>
                  <div className="btn_group">
                    <button type="submit" className="btn olive">
                      Send Mail
                    </button>
                  </div>
                </form>
              </div>
              {/* <Form></Form> */}
            </div>
          </div>
        </div>
        <SectionGridLines></SectionGridLines>
      </section>
    </>
  );
}

export default Investment;
